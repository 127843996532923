import { useEffect, useRef, useState } from 'react';
import { twMerge } from 'tailwind-merge';
import TextField from '@mui/material/TextField';
import ListItem from '@mui/material/ListItem';
import Autocomplete from '@mui/material/Autocomplete';
import CircularProgress from '@mui/material/CircularProgress';
import { useToggle } from '@/hooks/useToggle';
import { isBlank, isString } from '@/utils/strings';
import {
  type BaseAutoCompleteProps,
  classes,
  type ModifiedAutoCompleteProps,
  type SearchableDropdownOption,
  styles,
} from './utils';

export type { SearchableDropdownOption };

type SelectedOption<N extends string, T> = Record<
  N,
  SearchableDropdownOption<T>
>;

export interface SearchableDropdownProps<N extends string, T>
  extends ModifiedAutoCompleteProps<T | null> {
  disabled?: boolean;
  clearOnSelect?: boolean;
  autoFocus?: boolean;
  allowNew?: boolean;
  className?: string;
  wrapperClassName?: string;
  newLabel?: string;
  name: N;
  label: string;
  initialOptions: SearchableDropdownOption<T | null>[];
  onSearch: (search: string) => Promise<SearchableDropdownOption<T | null>[]>;
  onAddNew?: (search: string) => void;
  onSelect: (option: SelectedOption<N, T>) => void;
}

export default function SearchableDropdown<
  N extends string,
  T extends string | number,
>({
  name,
  label,
  initialOptions = [],
  onSelect,
  onAddNew,
  onSearch,
  wrapperClassName = '',
  newLabel = 'Add',
  className,
  disabled = false,
  clearOnSelect = false,
  autoFocus = false,
  allowNew = false,
  ...props
}: SearchableDropdownProps<N, T>) {
  const lastSearch = useRef<string | null>(null);
  const inputRef = useRef<HTMLDivElement | null>(null);
  const [inputValue, setInputValue] = useState('');
  const [options, setOptions] = useState(initialOptions);
  const [isSearching, toggleSearching] = useToggle(false);

  useEffect(() => {
    setOptions(initialOptions);
  }, [initialOptions]);

  const handleSelect: BaseAutoCompleteProps<T | null>['onChange'] = (
    e,
    option
  ) => {
    if (onAddNew && !isString(option) && option?.value === null) {
      onAddNew(inputValue);
    } else {
      onSelect({ [name]: option } as SelectedOption<N, T>);
    }
  };

  const handleSearch: BaseAutoCompleteProps<T | null>['onInputChange'] = async (
    e,
    search,
    reason
  ) => {
    setInputValue(search);
    if (reason === 'reset' && clearOnSelect) {
      setInputValue('');
      setOptions(initialOptions);
      return null;
    }
    if (search === lastSearch.current) {
      // Prevent running unnecessary searches
      return null;
    }
    lastSearch.current = search;
    toggleSearching(true);
    const newOptions = await onSearch(search);
    setOptions(newOptions);
    toggleSearching(false);
  };

  const renderOption: BaseAutoCompleteProps<T | null>['renderOption'] = (
    optionProps,
    option
  ) => {
    /*
      NOTE: Need to specify key separately to avoid error:
        A props object containing a "key" prop is being spread into JSX
      see https://stackoverflow.com/a/75968316/5839360
     */
    return (
      <ListItem
        {...optionProps}
        key={`${option.value}`}
        sx={styles.listItem}
        className={twMerge(optionProps.className, classes.listItem)}>
        <div className={classes.optionWrapper}>
          <label className={classes.label}>{option.label}</label>
          {option.description && (
            <p className={classes.description}>{option.description}</p>
          )}
        </div>
      </ListItem>
    );
  };

  const renderInput: BaseAutoCompleteProps<T | null>['renderInput'] = (
    params
  ) => {
    return (
      <TextField
        {...params}
        fullWidth
        ref={inputRef}
        className={className}
        label={label}
        InputProps={{
          ...params.InputProps,
          autoFocus,
          endAdornment: (
            <>
              {isSearching ? (
                <CircularProgress color="inherit" size={20} />
              ) : null}
              {params.InputProps.endAdornment}
            </>
          ),
        }}
      />
    );
  };

  const handleFilterOptions: SearchableDropdownProps<
    N,
    T | null
  >['filterOptions'] = (options, { inputValue }) => {
    if (isBlank(inputValue) || !allowNew || !onAddNew) return options;
    let filtered = [...options];
    const search = inputValue.toLowerCase();
    const match = options.some((o) => o.label.toLowerCase() === search);
    if (!match) {
      filtered.unshift({ label: `${newLabel} "${search}"`, value: null });
    }
    return filtered;
  };

  return (
    <>
      <Autocomplete<SearchableDropdownOption<T | null>, false, false, true, any>
        {...props}
        freeSolo
        forcePopupIcon
        disabled={disabled}
        id={name}
        autoComplete
        ListboxProps={{ sx: styles.listBox }}
        slotProps={{
          paper: {
            className: classes.paper,
          },
        }}
        className={twMerge(classes.autocomplete, wrapperClassName)}
        getOptionLabel={(option) => (isString(option) ? option : option.label)}
        isOptionEqualToValue={(option, value) => option.value === value.value}
        filterOptions={handleFilterOptions}
        options={options}
        value={props.value}
        inputValue={inputValue}
        noOptionsText="No results"
        onChange={handleSelect}
        onInputChange={handleSearch}
        renderOption={renderOption}
        renderInput={renderInput}
      />
    </>
  );
}
